<template>
  <v-container>
    <base-card>
      <div slot="heading" class="title font-weight-light">Template Details</div>
      <v-layout row wrap class="mt-5">
        <v-flex xs12>
          <v-card v-if="template" class="mx-auto" outlined>
            <v-card-text>
              <div class="overline mb-4">Template</div>
              <div class="headline black--text mb-1">
                {{ template.name }}
              </div>
              <div>{{ $t('templates.hostURL') }}: {{ template.host_url }}</div>
              <div v-if="template.owner_organization">
                {{ $t('templates.ownerOrganization') }}: {{ template.owner_organization.name }}
              </div>
              <div>{{ $t('templates.enabled') }}: {{ template.enabled }}</div>
              <div>
                {{ $t('templates.platformDatasetType') }}: {{ template.platform_dataset_type }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="teal" text @click="onEditClick"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <v-card v-if="template" class="mx-auto" outlined>
            <v-card-text>
              <div class="overline mb-4">
                {{ schemaType }}
              </div>
              <tree-view :data="template.result_schema" :options="{ maxDepth: 10 }" />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </base-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseCard from '../components/common/BaseCard.vue'

export default {
  components: {
    BaseCard,
  },
  mounted() {
    const { templateId } = this.$route.params
    this.fetchTemplateDetail(templateId)
  },
  computed: {
    ...mapState({
      template: (state) => state.template.template,
    }),
    schemaType() {
      switch (this.template.result_schema_type) {
        case 1:
          return 'FREE'
        case 2:
          return 'SELECTABLE'
        default:
          return 'UNKNOWN'
      }
    },
  },
  methods: {
    ...mapActions(['fetchTemplateDetail']),
    onEditClick() {
      this.$router.push({
        name: 'templateEdit',
        params: { templateId: this.$route.params.templateId },
      })
    },
  },
}
</script>

<style scoped></style>
